@require '/src/dev'

/*

    #PANEL

    Standardized themeable grouping element

    Markup example:
    <div class="panel"></div>

    .panel.panel--banana.panel--is-clickable


*/

// Map global to local variables
$panel-hover-outline = $global-hover-outline
$panel-radius = $global-radius

// Color variants
$panel-bg-success = $color-bg-success
$panel-bg-warning = $color-bg-warning
$panel-bg-info = $color-bg-info
$panel-bg-default = $color-bg-default
$panel-bg-error = $color-bg-error
$panel-bg-tips = $color-bg-info
$panel-bg-new = $color-bg-success

// partials, extend these in your component to mimic the panel behavior if you dont want to rely on core
$panel
    margin: 16px 16px 32px 16px
    +bp(min480)
        margin: 16px

$panel-bleed
    margin: 16px 0 48px 0  // content on white gets 64 visual vertical px of white, colored bg needs a little more to look balanced
    +bp(min480)
        margin: 16px

$panel-colored
    padding: 16px

// panel layout configuration
.panel
  @extend $panel 
  &--bleed
    @extend $panel-bleed // slightly different behaviour if you have a colored background or a bleeding panel
  &--success,
  &--warning,
  &--error,
  &--info,
  &--tips,
  &--new
    @extend $panel-colored // inner spacing to content when you have a colored background 



// panel visual configuration
.panel
  display: block
  word-break: break-word
  border-radius: $panel-radius
  &--is-clickable:focus,
  &--is-clickable:hover
    outline:$panel-hover-outline
    outline-offset: -2px // should subtract the border-width from $panel-hover-outline
    cursor:pointer
   // skins
  &--success
    background-color: $panel-bg-success
    &:focus.panel--is-clickable,
    &:hover.panel--is-clickable
      outline-color: @background-color - 20%
  &--warning
    background-color: $panel-bg-warning
    &:focus.panel--is-clickable,
    &:hover.panel--is-clickable
      outline-color: @background-color - 20%
  &--error
    background-color: $panel-bg-error
    &:focus.panel--is-clickable,
    &:hover.panel--is-clickable
      outline-color: @background-color - 20%
  &--info
    background-color: $panel-bg-info
    &:focus.panel--is-clickable,
    &:hover.panel--is-clickable
      outline-color: @background-color - 20%
  &--tips
    background-color: $panel-bg-tips
    border: 2px solid $toothpaste
    &:focus.panel--is-clickable,
    &:hover.panel--is-clickable
      outline-color: $toothpaste - 20%
  &--new
    background-color: $panel-bg-new
    border: 2px solid $pea
    &:focus.panel--is-clickable,
    &:hover.panel--is-clickable
      outline-color: $pea - 20%     