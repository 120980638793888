$select-all
    user-select: all

$show-cursor
    cursor: pointer

$non-selectable
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
    -webkit-touch-callout: none
    user-select: none
